import _objectSpread from "/Users/teerapatcheung/Desktop/0-Project/logistics/logistics_metronic_vue/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import "core-js/modules/es.array.join.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapGetters } from "vuex";
export default {
  name: "KTSubheader",
  props: {
    breadcrumbs: Array,
    title: String
  },
  computed: _objectSpread(_objectSpread({}, mapGetters(["layoutConfig", "getIsAdmin"])), {}, {
    /**
     * Check if subheader width is fluid
     */
    widthFluid: function widthFluid() {
      return this.layoutConfig("subheader.width") === "fluid";
    },
    subheaderClasses: function subheaderClasses() {
      var classes = [];
      var style = this.layoutConfig("subheader.style");

      if (style) {
        classes.push(style);

        if (style === "solid") {
          classes.push("bg-white");
        }

        if (this.layoutConfig("subheader.fixed")) {
          classes.push("border-top");
        }
      }

      return classes.join(" ");
    }
  })
};