//
//
//
//
//
//
//
//
//
export default {
  name: "LoaderOverlay",
  props: {
    logo: String,
    spinnerClass: String
  }
};