//
//
//
//
//
//
//
//
//
//
import KTLayoutScrolltop from "@/assets/js/layout/extended/scrolltop.js";
export default {
  name: "KTScrollTop",
  mounted: function mounted() {
    // Init Scrolltop
    KTLayoutScrolltop.init(this.$refs["kt_scrolltop"]);
  }
};