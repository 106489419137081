import _objectSpread from "/Users/teerapatcheung/Desktop/0-Project/logistics/logistics_metronic_vue/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapGetters } from "vuex";
import { WEBSITE_NAME, CUS_URL } from "@/config";
export default {
  name: "KTFooter",
  computed: _objectSpread(_objectSpread({}, mapGetters(["layoutConfig"])), {}, {
    /**
     * Check if footer container is fluid
     */
    widthFluid: function widthFluid() {
      return this.layoutConfig("footer.width") === "fluid";
    }
  }),
  data: function data() {
    return {
      CUS_URL: CUS_URL,
      WEBSITE_NAME: WEBSITE_NAME
    };
  }
};