import _objectSpread from "/Users/teerapatcheung/Desktop/0-Project/logistics/logistics_metronic_vue/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapGetters } from "vuex";
import { IS_SELLER } from "@/config";
export default {
  computed: _objectSpread({}, mapGetters(["getCurrentNoti"])),
  data: function data() {
    return {
      IS_SELLER: IS_SELLER
    };
  }
};